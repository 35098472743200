<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Profile"></Icon>
        ورود به حساب
      </h2>
      <p class="mt-3">
        با وارد شدن به حساب خود می‌توانید از خدمات ما از جمله سفارش تبلیغات بهره مند شوید
      </p>
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 sm:pl-3">
          <ErrorBox :errors="errors" class="mb-5" />
          <form @submit.prevent="onSubmit">
            <MnrText
              title="موبایل یا ایمیل"
              inputDir="ltr"
              placeholder="موبایل یا ایمیل خود را وارد کنید"
              v-model="loginInput.value"
              :status="errors?.loginInput ? 'error' : ''"
              :msg="errors?.loginInput?.[0]"
            ></MnrText>
            <MnrPassword
              title="رمز عبور"
              inputDir="ltr"
              class="mt-3"
              placeholder="رمز عبور خود را وارد کنید"
              v-model="password.value"
              :status="errors?.password ? 'error' : ''"
              :msg="errors?.password?.[0]"
            ></MnrPassword>
            <!-- <button class="bg-coolGray-500 block p-3 w-full rounded-md mt-5">ورود</button> -->
            <Button class="mt-5" color="sky" type="submit" :loading="disabaledForm">
              ورود
            </Button>
            <Button to="/Register" class="mt-5" color="blueGray" :light="true">
              ثبت نام
            </Button>
          </form>
        </div>
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 mt-5 sm:pr-3 sm:mt-0">
          <p class="text-justify">
            می‌توانید با استفاده از یکی از رباتها به راحتی وارد پنل شوید. برای این منظور
            باید ابتدا به تلگرام کانکت شوید!
          </p>
          <Button
            href="https://t.me/sefareshtabligh_bot?start=panel"
            target="_blank"
            class="mt-3"
            color="teal"
          >
            ورود با ربات تبلیغات
          </Button>
          <Button
            href="https://t.me/mnrG1bot?start=panel"
            target="_blank"
            class="mt-3"
            color="red"
          >
            ورود با ربات گروه
          </Button>
          <Button
            href="https://t.me/mnrcbot?start=panel"
            target="_blank"
            class="mt-3"
            color="cyan"
          >
            ورود با ربات کانال
          </Button>
          <p class="mt-5 text-justify">
            شما میتوانید به صورت مستقیم به یکی از ربات ها پیام دهید و با زدن دکمه ورود به
            پنل و دریافت لینک از ربات وارد پنل شوید
          </p>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    Icon,
    MnrText,
    MnrPassword,
    Button,
    BodySimple,
    ErrorBox,
  },
  data() {
    return {
      disabaledForm: false,
      errors: {},
      loginInput: {
        value: "",
      },
      password: {
        value: "",
      },
    };
  },
  mounted() {
    // console.log(this.$store.state.user !== false)
    // if (this.$store.state.user !== false) {
    //   this.$router.push("/panel");
    // }
  },
  methods: {
    onSubmit() {
      let $this = this;
      $this.errors = {};

      if ($this.loginInput.value.length < 1) {
        $this.errors.loginInput = ["لطفا ایمیل یا موبایل خود را وارد کنید"];
      }
      if ($this.password.value.length < 1) {
        $this.errors.password = ["لطفا رمز عبور خود را وارد کنید"];
      }

      if (Object.keys($this.errors).length) return;

      $this.disabaledForm = true;

      $this.$axios
        .post("/api/login", {
          loginInput: $this.loginInput.value,
          password: $this.password.value,
        })
        .then(function (response) {
          // console.log("res", response);
          localStorage.setItem("token", response.data.token);
          $this.$store.commit("userLogged", response.data.user);
          $this.$router.push("/panel");
          // $this.disabaledForm = false;
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
          if (error.status == 406) {
            $this.$router.push("/panel");
          }
        });
    },
  },
};
</script>
